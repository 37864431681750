.list ul {
  max-height: 30vh;
  overflow: auto;
}

.placeholder {
  display: flex;
  flex-flow: row wrap;
  padding: 0 3rem;
}

.pharmaCard {
  flex: 0 1 300px;
  margin: 1rem;
}

.pharmaCard img {
  width: 270px;
  height: 270px;
}

.pharmaCard h6 {
  height: 1rem;
  margin-top: 1rem;
}

.pharmaCard h5 {
  height: 2rem;
  margin-top: 1rem;
}

.pharmaCard h4 {
  height: 4rem;
}

.pharmaCard h3 {
  height: 3rem;
}

[data-present="yes"].present {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 3rem;
  background-color: whitesmoke;
}

[data-present="yes"] h1 {
  position: fixed;
  z-index: 12;
  top: 3rem;
  left: 3rem;
}

[data-present="yes"] > div > h4 {
  position: fixed;
  z-index: 12;
  right: 3rem;
  bottom: .5rem;
}
